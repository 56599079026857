<template>
  <div>
    <div style="min-width: 300px">
      <!-- HEADER -->
      <v-app-bar style="background-color: #e6eaf1">
        <div class="logo">
          <router-link to="/">
            <v-img style="width: 150px; height: 55px; margin: 0px 150px 0px 60px" src="../assets/images/Asset 4@3x.png">
            </v-img>
          </router-link>
        </div>

        <template>
          <v-tabs id="tabs" v-model="tab">
            <v-tab @click="tabRouter(item)" v-for="item in tabs" :key="item.tab">
              {{ item.tab }}
            </v-tab>
          </v-tabs>
        </template>

        <div>
          <router-link style="text-decoration: none; color: inherit" to="/submit-form">
            <v-btn id="btn" dark color="var(--clr-primary)" rounded>
              Submit Form
            </v-btn>
          </router-link>

          <router-link style="text-decoration: none; color: inherit" to="/">
            <v-btn id="IDHome" dark rounded color="#4173A3"> Home </v-btn>
          </router-link>

          <v-btn icon id="Icon">
            <v-app-bar-nav-icon large @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
          </v-btn>
        </div>
      </v-app-bar>

      <!-- NAVIGATION DRAWER -->
      <v-navigation-drawer v-model="drawer" absolute temporary>
        <v-list nav dense>
          <v-list-item-group active-class="deep-purple--text text--accent-4">
            <v-list-item>
              <v-img src="../assets/images/Asset 4@3x.png"></v-img>
            </v-list-item>

            <v-list-item @click="tabRouter(item)" v-for="item in tabs" :key="item.id" :inactive="true" active-class="">
              {{ item.tab }}
            </v-list-item>

            <v-btn rounded color="#2F5E8B" width="200px">
              <router-link to="/submit-form" style="text-decoration: none; color: inherit">Submit Form</router-link>
            </v-btn>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

      <!-- ROUTER VIEW -->
      <v-main>
        <router-view />
      </v-main>

      <!-- FOOTER -->
      <v-footer id="footerMain" color="#2F5E8B" app bottom absolute padless>
        <div id="footerText" style="display: flex; width: 100%; height: 50px">
          <v-card-text id="copyRight" style="width: 50%; text-align: right">
            <p id="footerText1" style="color: white; font-size: 12px">
              Copyright &copy; 2022 Acadletics.com limited. All rights reserved.
            </p>
          </v-card-text>

          <v-card-text id="term" style="
              display: flex;
              justify-content: center;
              width: 50%;
              font-size: 12px;
            ">
            <router-link to="/terms" style="text-decoration: none">
              <p id="footerText21" style="color: white">Terms & Condition</p>
            </router-link>
            <v-divider vertical style="margin: 3px 10px 0px 10px; border-color: white"></v-divider>
            <router-link to="/policy" style="text-decoration: none">
              <p id="footerText22" style="color: white">Privacy Policy</p>
            </router-link>
          </v-card-text>
        </div>
      </v-footer>
    </div>
  </div>
</template>

<script>
const routes = {
  terms: 0,
  policy: 0,
  home: 0,
  magazine: 1,
  magazineId: 1,
  student: 2,
  studentId: 2,
  school: 3,
  schoolId: 3,
  schoolStudents: 3,
  about: 4,
  contact: 5,
  SubmitForm: 5
};

export default {
  updated() {
    this.tab = routes[this.$route.name];
    if (
      this.$route.name == 'SubmitForm' ||
      this.$route.name == 'terms' ||
      this.$route.name == 'policy'
    ) {
      document.getElementById('tabs').style.display = 'none';
      document.getElementById('btn').style.display = 'none';
      document.getElementById('IDHome').style.display = 'block';
      document.getElementById('Icon').style.marginTop = '-30px';
    } else {
      document.getElementById('tabs').style.display = 'block';
      document.getElementById('btn').style.display = 'block';
      document.getElementById('IDHome').style.display = 'none';
      document.getElementById('Icon').style.marginTop = '0px';
    }
  },

  data() {
    return {
      drawer: false,
      tab: null,
      tabs: [
        {
          tab: 'Home',
          route: ''
        },
        {
          tab: 'Magazines',
          route: 'magazines'
        },
        {
          tab: 'Students',
          route: 'students'
        },
        {
          tab: 'Schools',
          route: 'schools'
        },
        {
          tab: 'About',
          route: 'about'
        },
        {
          tab: 'Contact',
          route: 'contact'
        }
      ]
    };
  },
  methods: {
    tabRouter(item) {
      if (this.$route.path === '/' + item.route) {
        return;
      } else {
        this.$router.push('/' + item.route);
      }
    }
  }
};
</script>


<style>
.logo {
  width: 200px;
}

#tabs {
  margin-left: 320px !important;
}

#Icon {
  display: none;
}

#IDHome {
  margin-left: 1000% !important;
}

@media only screen and (max-width: 1300px) {
  #tabs {
    margin-left: 250px !important;
  }

  #IDHome {
    margin-left: 800% !important;
  }
}

@media only screen and (max-width: 1250px) {
  #tabs {
    margin-left: 160px !important;
  }
}

@media only screen and (max-width: 1150px) {
  #tabs {
    margin-left: 100px !important;
  }
}

@media only screen and (max-width: 1100px) {
  #tabs {
    margin-left: 20px !important;
  }

  #IDHome {
    margin-left: 600% !important;
  }
}

@media only screen and (max-width: 1000px) {
  #tabs {
    display: none !important;
  }

  #btn {
    display: none !important;
  }

  #Icon {
    display: block;
    margin-left: 700px;
  }

  .logo {
    margin-left: -50px;
  }
}

@media only screen and (max-width: 930px) {
  #Icon {
    margin-left: 600px;
  }
}

@media only screen and (max-width: 850px) {
  #footerText1 {
    text-align: left;
    line-height: 18px;
  }

  #Icon {
    margin-left: 500px;
  }
}

@media only screen and (max-width: 750px) {
  #Icon {
    margin-left: 400px;
  }
}

@media only screen and (max-width: 620px) {
  #Icon {
    margin-left: 300px;
  }
}

@media only screen and (max-width: 530px) {
  #Icon {
    margin-left: 200px;
  }

  #footerText1 {
    line-height: 15px;
    font-size: 10px !important;
  }

  #footerText21 {
    font-size: 8px;
  }

  #footerText22 {
    font-size: 8px;
  }

  #footerMain {
    padding-bottom: 22px;
  }
}

@media only screen and (max-width: 430px) {
  #Icon {
    margin-left: 150px;
  }
}

@media only screen and (max-width: 400px) {
  .logo {
    margin-left: -80px;
  }

  #footerText {
    flex-direction: column;
  }

  #copyRight {
    width: 100% !important;
    padding: 10px 0px 0px 20px;
  }

  #term {
    width: 100% !important;
    height: 20px;
    padding: 0px;
    margin-top: -6px;
  }
}

@media only screen and (max-width: 350px) {
  #Icon {
    margin-left: 80px;
  }
}
</style>