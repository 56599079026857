<template>
  <div style="display: flex;">
    <div class="img">
      <v-img style="margin-left: 20px; margin-top: 60px" width="68px" height="58px"
        src="../../assets/images/Group 39699.png"></v-img>
    </div>

    <div class="main">
      <div class="search">
        <v-col class="sBar" cols="12" sm="6" md="8">
          <v-text-field @input="searchMaga" @keypress.enter="searchSchool" v-model="search" label="Search" outlined
            dense style="border-radius: 10px"></v-text-field>
        </v-col>

        <v-btn @click="searchSchool" class="sIcon" color="#4173A3" dark>
          <v-icon>search</v-icon>
        </v-btn>
      </div>

      <div class="schoolMain">
        <div v-show="noFound" style="width: 100%">
          <h4 style="text-align: center">"No School found"</h4>
        </div>
        <v-row class="schools">
          <v-col style="max-height: 280px" v-show="content" class="school" cols="12" sm="4" md="4"
            v-for="school in schools" :key="school.id">
            <h4>{{ school.name }}</h4>
            <div style="display: flex; align-items: center; margin-top: 20px">
              <v-btn @click="schoolWebsite(school.website)" small style="
                  margin: 10px 0px;
                  color: white;
                  background-color: #4173a3;
                ">
                <v-icon small style="margin-right: 5px" color="white">mdi-web</v-icon>
                Website
              </v-btn>
              <v-btn @click="schoolStudents(school._id)" small style="
                  margin: 10px 0px;
                  color: white;
                  background-color: #4173a3;
                  margin-left: 24px;
                ">
                <v-icon small style="margin-right: 5px" color="white">mdi-account-group</v-icon>
                Students
              </v-btn>
            </div>
          </v-col>

          <v-col class="school" v-show="loader" cols="12" sm="4" md="4" v-for="i in 6" :key="i">
            <v-skeleton-loader style="margin-top: 20px" type="sentences" />
            <div style="
                display: flex;
                justify-content: space-between;
                padding: 0px 20px;
                margin-top: 20px;
              ">
              <v-skeleton-loader type="table-cell" />
              <v-skeleton-loader type="table-cell" />
            </div>
          </v-col>
        </v-row>

        <div class="ads">
          <div class="adsImage">
            <v-img @click="webLink" v-if="this.$vuetify.breakpoint.width > 800" height="100%" width="100%" contain
              :src="this.webImage">
            </v-img>
            <v-img id="andriodAdd" @click="androidLink" v-if="this.$vuetify.breakpoint.width < 800" contain
              height="100%" width="100%" :src="this.androidImage">
            </v-img>
          </div>
        </div>
      </div>
    </div>

    <loading v-model="searchLoading" message="Searching..."></loading>
  </div>
</template>

<script>
import axios from 'axios';
import loading from '../../components/LoadingDialog.vue';

export default {
  data() {
    return {
      noFound: false,
      searchLoading: false,
      search: null,
      content: false,
      loader: true,
      schools: null,
      webImage: null,
      androidImage: null,
      webUrl: null,
      androidUrl: null
    };
  },
  methods: {
    searchMaga() {
      if (this.search) {
        this.schools = this.schools.filter((school) => {
          return this.search
            .toLowerCase()
            .split(' ')
            .every((v) => school.name.toLowerCase().includes(v));
        });
        if (this.schools.length < 1) {
          this.noFound = true;
          document.getElementById('andriodAdd').style.marginTop = '120%';
        }
      } else {
        this.noFound = false;
        this.loadData();
        document.getElementById('andriodAdd').style.marginTop = '0';
      }
    },

    webLink() {
      let url;
      if (this.webUrl.includes('http')) url = this.webUrl;
      else url = 'https://' + this.webUrl;
      window.open(url);
    },

    androidLink() {
      let url;
      if (this.androidUrl.includes('http')) url = this.androidUrl;
      else url = 'https://' + this.androidUrl;
      window.open(url);
    },

    async loadAndroidAdds() {
      await axios
        .get('/advertisements/query/filter?placement=magazine--bottom&type=1')
        .then((result) => {
          this.androidImage =
            'https://api.acadletics.com/uploads/' + result.data[0].image.name;
          this.androidUrl = result.data[0].url;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async loadWebAdds() {
      await axios
        .get('/advertisements/query/filter?placement=magazine--right&type=2')
        .then((result) => {
          this.webImage =
            'https://api.acadletics.com/uploads/' + result.data[0].image.name;
          this.webUrl = result.data[0].url;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async loadData() {
      await axios
        .get('/school')
        .then((result) => {
          this.schools = result.data;
          if (result.data.length > 0) {
            this.loader = false;
            this.content = true;
          } else {
            this.loader = false;
            this.noFound = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    viewMore(item) {
      this.$router.push(`/schools/${item._id}`);
    },

    async searchSchool() {
      this.searchLoading = true;
      await axios
        .get('/school/search?query=' + this.search)
        .then((result) => {
          if (result.data.length > 0) {
            this.noFound = false;
            this.searchLoading = false;
            this.schools = result.data;
          } else {
            this.searchLoading = false;
            this.schools = '';
            this.noFound = true;
            document.getElementById('andriodAdd').style.marginTop = '120%';
            console.log('yes');
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    schoolWebsite(e) {
      let url;
      if (e.includes('http')) url = e;
      else url = 'https://' + e;
      window.open(url);
    },
    schoolStudents(item) {
      this.$router.push(`/schools/${item}/students`);
    }
  },
  mounted() {
    this.loadData();
    this.loadWebAdds();
    this.loadAndroidAdds();
  },
  components: {
    loading
  }
};
</script>

<style>
.img {
  width: 10%;
  justify-content: center;
  align-items: center;
}

.search {
  display: flex;
  margin-left: 20px;
}

.sIcon {
  margin-top: 12px;
  margin-left: -4%;
  height: 40px !important;
}

.setting {
  margin-left: 2%;
  margin-top: 13px;
  background-color: transparent;
}

.main {
  width: 90%;
  margin-top: 20px;
}

.schoolMain {
  padding: 20px;
  display: flex;
  margin-bottom: 100px;
}

.schools {
  width: 60%;
  /* max-height: 160px !important; */
}

.school {
  margin: 20px 0px 0px 20px;
  max-width: 280px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(180, 192, 209) 0px 0px 0px 1px inset;
}

.schoolImg {
  height: 150px;
}

.school h4 {
  height: 34px;
  margin-top: 15px;
  overflow: hidden;
  line-height: 16px;
}

.school p {
  font-size: 12px;
  height: 40px;
  overflow: hidden;
}

.viewMore {
  padding: 5px 15px;
  margin-top: -12px;
  text-align: right;
  cursor: pointer;
}

.ads {
  width: 20%;
  cursor: pointer;
}

.adsImage {
  width: 100%;
}

@media only screen and (max-width: 1000px) {
  .schools {
    width: 100%;
  }

  .img {
    display: none;
  }

  .main {
    width: 100%;
  }
}

@media only screen and (max-width: 800px) {
  .main {
    padding: 0px 20px;
  }

  .schoolMain {
    flex-direction: column;
    align-items: center;
    margin: 0px;
    padding: 0px;
  }

  .ads {
    margin: 50px 0px 30px 0px;
    width: 100%;
  }

  .adsImage {
    height: 100px;
  }
}

@media only screen and (max-width: 600px) {
  .img {
    display: none;
  }

  .school {
    margin: 20px 0px;
  }

  .schools {
    display: flex;
    justify-content: center;
  }

  .search {
    margin-left: -10px;
    margin-right: 40px;
  }

  .setting {
    display: none;
  }

  .sIcon {
    margin-left: -8%;
  }
}
</style>
