<template>
  <div style="display: flex">
    <div class="img">
      <v-img
        style="margin-left: 20px; margin-top: 60px"
        width="68px"
        height="58px"
        src="../../assets/images/Group 39699.png"
      ></v-img>
    </div>

    <div class="main">
      <div class="schoolMain">
        <div v-show="noFound" style="width: 100%">
          <h4 style="text-align: center">"No Students found"</h4>
        </div>

        <v-row class="schools">
          <v-col
            @click="viewMore(student)"
            v-show="content"
            style="cursor: pointer; max-height: 300px"
            class="school"
            cols="12"
            sm="4"
            md="4"
            v-for="student in students"
            :key="student.id"
          >
            <v-card class="schoolImg">
              <v-img
                contain
                height="100%"
                :src="
                  ' https://api.acadletics.com/uploads/' + student.image.name
                "
                alt="image"
              />
            </v-card>
            <h4>
              {{ student.name }}
            </h4>
            <h6 style="color: #4173a3">
              {{ student.type }}
            </h6>
            <div class="viewMore">
              <h6 style="margin-top: 20px" @click="viewMore(student)">
                View More
              </h6>
            </div>
          </v-col>

          <v-col
            class="school"
            v-show="loader"
            cols="12"
            sm="4"
            md="4"
            v-for="i in 6"
            :key="i"
          >
            <v-skeleton-loader type="image" style="height: 150px">
            </v-skeleton-loader>
            <v-skeleton-loader type="table-cell"> </v-skeleton-loader>
            <v-skeleton-loader type="table-cell" style="margin-top: -20px">
            </v-skeleton-loader>
            <v-skeleton-loader
              type="table-cell"
              style="margin-left: 60%; margin-top: -20px"
            >
            </v-skeleton-loader>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
  
<script>
import axios from 'axios';

export default {
  data() {
    return {
      noFound: false,
      content: false,
      loader: true,
      students: null
    };
  },
  methods: {
    async loadData() {
      await axios
        .get('/student/status/get/all-nominated/' + this.$route.params.id)
        .then((result) => {
          this.students = result.data;
          if (result.data.length > 0) {
            this.loader = false;
            this.content = true;
          } else {
            this.loader = false;
            this.noFound = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    viewMore(item) {
      this.$router.push(`/students/${item._id}`);
    }
  },
  mounted() {
    this.loadData();
  }
};
</script>
  