<template>
  <div class="Main">
    <div class="Card" v-show="content">
      <v-card style="width: 100%; height: 200px">
        <v-img
          contain
          width="100%"
          height="100%"
          :src="' https://api.acadletics.com/uploads/' + this.magazine.name"
          alt="image"
        />
      </v-card>
      <div class="content">
        <p style="font-size: 16px; margin: 20px 0px 0px 0px">
          Created At:
          {{
            new Date(magazine.createdAt).getDate() +
            '-' +
            new Date(magazine.createdAt).getMonth() +
            '-' +
            new Date(magazine.createdAt).getFullYear()
          }}
        </p>
        <h4 style="font-size: 25px">{{ this.magazine.title }}</h4>
        <p style="color: #4173a3">{{ this.magazine.type }}</p>
        <p style="font-size: 14px; margin-top: 0px; word-break: break-word">
          {{ this.magazine.description }}
        </p>
        <v-col class="d-flex justify-center" style="margin-top: 20px">
          <div style="margin: 0px 10px">
            <v-btn @click="downloadMagazine" dark color="#4173A3" elevation="0"
              >Read Now
            </v-btn>
          </div>

          <div style="margin: 0px 10px">
            <v-btn @click="downloadMagazine" dark color="#4173A3" elevation="0"
              >Download
            </v-btn>
          </div>
        </v-col>
      </div>
    </div>

    <div v-show="loader" class="Card">
      <v-skeleton-loader type="image" />
      <v-skeleton-loader type="table-cell" />
      <v-skeleton-loader type="heading" />
      <v-skeleton-loader type="table-cell" />
      <v-skeleton-loader type="sentences" />
      <div
        class="btnLoader"
        style="
          display: flex;
          justify-content: space-between;
          margin: 20px 35% 0 35%;
        "
      >
        <v-skeleton-loader type="button" />
        <v-skeleton-loader type="button" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      content: false,
      loader: true,
      magazine: [
        {
          title: null,
          name: null,
          type: null,
          createdAt: null,
          description: null,
          file: null
        }
      ]
    };
  },
  methods: {
    async loadData() {
      await axios
        .get('magazine/' + this.$route.params.id)
        .then((result) => {
          this.magazine.name = result.data.image.name;
          this.magazine.title = result.data.name;
          this.magazine.type = result.data.type;
          this.magazine.createdAt = result.data.createdAt;
          this.magazine.description = result.data.description;
          this.magazine.file = result.data.file.name;
          this.loader = false;
          this.content = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    downloadMagazine() {
      let url = 'https://api.acadletics.com/uploads/' + this.magazine.file;
      window.location.href = url;
    }
  },
  mounted() {
    this.loadData();
  }
};
</script>

<style>
.Main {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Card {
  margin: 50px 0px;
  padding: 10px;
  width: 50%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

@media screen and (max-width: 800px) {
  .Card {
    width: 80%;
  }

  .btnLoader {
    margin: 20px 20% 0 20% !important;
  }
}

@media screen and (max-width: 400px) {
  .Card {
    width: 90%;
  }
}
</style>
