<template>
  <div style="display: flex">
    <div class="img">
      <v-img style="margin-left: 20px; margin-top: 60px" width="68px" height="58px"
        src="../../assets/images/Group 39699.png"></v-img>
    </div>

    <div class="main">
      <div class="search">
        <v-col class="sBar" cols="12" sm="6" md="8">
          <v-text-field @keypress.enter="searchMagazines" @input="searchMaga" v-model="search" label="Search" outlined
            dense style="border-radius: 10px"></v-text-field>
        </v-col>
        <v-btn @click="searchMagazines" dark class="sIcon" color="#4173A3">
          <v-icon>search</v-icon>
        </v-btn>
      </div>

      <div class="schoolMain">
        <div v-show="noFound" style="width: 100%">
          <h4 style="text-align: center">"No Magazine found"</h4>
        </div>
        <v-row class="schools">
          <v-col @click="viewMore(magazine)" v-show="content" style="cursor: pointer; max-height: 262px" class="school"
            cols="12" sm="4" md="4" v-for="magazine in magazines" :key="magazine.id">
            <v-card class="schoolImg">
              <v-img contain height="100%" :src="
                'https://api.acadletics.com/uploads/' + magazine.image.name
              " alt="image" />
            </v-card>
            <h4>
              {{ magazine.name }}
            </h4>
            <h6 style="color: #4173a3">
              {{ magazine.type }}
            </h6>
            <div class="viewMore">
              <h6 style="margin-top: 20px" @click="viewMore(magazine)">
                View More
              </h6>
            </div>
          </v-col>

          <v-col class="school" v-show="loader" cols="12" sm="4" md="4" v-for="i in 6" :key="i">
            <v-skeleton-loader type="image" style="height: 150px">
            </v-skeleton-loader>
            <v-skeleton-loader type="table-cell"> </v-skeleton-loader>
            <v-skeleton-loader type="table-cell" style="margin-top: -20px">
            </v-skeleton-loader>
            <v-skeleton-loader type="table-cell" style="margin-left: 60%; margin-top: -20px">
            </v-skeleton-loader>
          </v-col>
        </v-row>

        <div class="ads">
          <div class="adsImage">
            <v-img @click="webLink" v-if="this.$vuetify.breakpoint.width > 800" height="100%" width="100%" contain
              :src="this.webImage">
            </v-img>
            <v-img id="andriodAdd" @click="androidLink" v-if="this.$vuetify.breakpoint.width < 800" contain
              height="100%" width="100%" :src="this.androidImage">
            </v-img>
          </div>
        </div>

        <loading v-model="loading" message="Searching..."></loading>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import loading from '../../components/LoadingDialog.vue';

export default {
  data() {
    return {
      noFound: false,
      loading: false,
      search: null,
      content: false,
      loader: true,
      magazines: null,
      webImage: null,
      androidImage: null,
      webUrl: null,
      androidUrl: null
    };
  },
  methods: {
    searchMaga() {
      if (this.search) {
        this.magazines = this.magazines.filter((magazine) => {
          return this.search
            .toLowerCase()
            .split(' ')
            .every((v) => magazine.name.toLowerCase().includes(v));
        });
        if (this.magazines.length < 1) {
          this.noFound = true;
          document.getElementById('andriodAdd').style.marginTop = '120%';
        }
      } else {
        this.noFound = false;
        this.loadData();
        document.getElementById('andriodAdd').style.marginTop = '0';
      }
    },
    async loadData() {
      await axios
        .get('/magazine')
        .then((result) => {
          if (result.data.length > 0) {
            this.noFound = false;
            this.loader = false;
            this.magazines = result.data;
            this.content = true;
          } else {
            this.loader = false;
            this.noFound = true;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async loadAndroidAdds() {
      await axios
        .get('/advertisements/query/filter?placement=magazine--bottom&type=1')
        .then((result) => {
          this.androidImage =
            'https://api.acadletics.com/uploads/' + result.data[0].image.name;
          this.androidUrl = result.data[0].url;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async loadWebAdds() {
      await axios
        .get('/advertisements/query/filter?placement=magazine--right&type=2')
        .then((result) => {
          this.webImage =
            'https://api.acadletics.com/uploads/' + result.data[0].image.name;
          this.webUrl = result.data[0].url;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    viewMore(item) {
      this.$router.push(`/magazines/${item._id}`);
    },

    async searchMagazines() {
      this.loading = true;

      await axios
        .get('/magazine/search?query=' + this.search)
        .then((result) => {
          if (result.data.length > 0) {
            this.noFound = false;
            this.loading = false;
            this.loader = false;
            this.magazines = result.data;
          } else {
            this.loading = false;
            this.loader = false;
            this.magazines = '';
            this.noFound = true;
            document.getElementById('andriodAdd').style.marginTop = '120%';
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    webLink() {
      let url;
      if (this.webUrl.includes('http')) url = this.webUrl;
      else url = 'https://' + this.webUrl;
      window.open(url);
    },

    androidLink() {
      let url;
      if (this.androidUrl.includes('http')) url = this.androidUrl;
      else url = 'https://' + this.androidUrl;
      window.open(url);
    }
  },
  mounted() {
    this.loadData();
    this.loadWebAdds();
    this.loadAndroidAdds();
  },
  components: {
    loading
  }
};
</script>
