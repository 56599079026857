<template>
  <div>
    <!-- HEADER -->
    <v-main class="homeHeaderBG">
      <div class="homeHeader">
        <v-card-text class="homeHeader-content1">
          <v-card-title class="homeHeader-content1-title"
            >Academics and Athletics</v-card-title
          >
          <v-card-subtitle class="homeHeader-content1-subtitle"
            >"We recognize students' achievements both academically and
            athletically. We are a magazine and app that Athletic Directors,
            coaches, teachers, academic advisors, students, and parents can
            nominate students and give a short summary of why they are
            nominating them. If chosen, they will be placed in our monthly
            magazine and on our app.
          </v-card-subtitle>
          <v-row class="headerBtn" style="margin: 10px 0px 0px 20px">
            <v-btn
              @click="downloadMagazine"
              rounded
              dark
              color="var(--clr-primary)"
              style="border: 1px solid white"
            >
              <v-icon small style="margin-right: 5px">download</v-icon>
              Magazine
            </v-btn>
          </v-row>

          <!-- <div class="headerBtnRow">
            <a
              href="https://apps.apple.com/us/app/academics-and-athletics/id1632715168"
              style="text-decoration: none"
            >
              <div class="appleBtn">
                <div class="appleBtnImg">
                  <v-img
                    contain
                    height="80%"
                    src="../assets/images/apple-logo.png"
                  ></v-img>
                </div>
                <div class="appleBtnContent">
                  <h6 style="font-weight: 100; font-size: 10px; color: white">
                    Download on the
                  </h6>
                  <h6
                    style="
                      color: white;
                      font-weight: 100;
                      font-size: 14px;
                      margin-top: -6px;
                    "
                  >
                    Apple Store
                  </h6>
                </div>
              </div>
            </a>

            <a
              href="https://play.google.com/store/apps/details?id=com.sparkosol.acadletics&hl=en&gl=US"
              style="text-decoration: none"
            >
              <div class="playstoreBtn">
                <div class="appleBtnImg">
                  <v-img
                    contain
                    height="80%"
                    src="../assets/images/playstore.png"
                  ></v-img>
                </div>
                <div class="appleBtnContent">
                  <h6 style="font-weight: 100; font-size: 10px; color: white">
                    Download on the
                  </h6>
                  <h6
                    style="
                      color: white;
                      font-weight: 100;
                      font-size: 14px;
                      margin-top: -5px;
                    "
                  >
                    Play Store
                  </h6>
                </div>
              </div>
            </a>
          </div> -->
        </v-card-text>

        <v-card-text class="homeHeader-content2">
          <v-img
            class="homeHeader-football"
            src="../assets/images/football.png"
          ></v-img>

          <v-img
            class="homeHeader-man"
            src="../assets/images/main img.png"
          ></v-img>

          <v-img
            class="homeHeader-seek"
            src="../assets/images/Seek.png"
          ></v-img>
        </v-card-text>
      </div>
    </v-main>

    <!-- Sponsers -->
    <div v-show="sponserContent">
      <v-container
        style="display: flex; justify-content: center; margin-top: 20px"
      >
        <v-card-title
          style="
            text-transform: uppercase;
            color: #4173a3;
            font-size: 30px;
            font-weight: 700;
          "
          >Sponsors</v-card-title
        >
      </v-container>

      <v-container style="width: 100%; height: 100%; margin-bottom: 50px">
        <v-row style="display: flex; justify-content: center">
          <v-col
            style="max-height: 80px; max-width: 200px; margin: 10px"
            cols="12"
            sm="4"
            md="2"
            v-for="(sponser, index) in sponsers"
            :key="index"
          >
            <v-img
              @click="sponsorLink(sponser.link)"
              style="cursor: pointer"
              id="sponsers"
              height="100%"
              width="100%"
              contain
              :src="' https://api.acadletics.com/uploads/' + sponser.logo.name"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>

      <!-- <div
        style="
          width: 100%;
          height: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
        "
      >
        <div class="loader" v-show="sponserLoader" style="margin-left: 33.5%">
          <div class="loaderItem"></div>
          <div class="loaderItem"></div>
          <div class="loaderItem"></div>
        </div>

        <marquee
          duplicated="true"
          BEHAVIOR="alternate"
          onmouseover="this.stop();"
          onmouseout="this.start();"
          scrolldelay="200"
        >
          <div style="display: flex">
            <v-img
              id="sponsers"
              style="margin: 0px 50px"
              v-for="sponser in sponsers"
              :key="sponser"
              height="60px"
              width="100px"
              contain
              :src="' https://api.acadletics.com/uploads/' + sponser.logo.name"
            ></v-img>
          </div>
        </marquee>
      </div> -->
    </div>

    <!-- Our Stars -->
    <v-container
      style="display: flex; justify-content: center; margin-top: 20px"
    >
      <v-card-title
        style="
          text-transform: uppercase;
          color: #4173a3;
          font-size: 30px;
          font-weight: 700;
        "
        >Our Stars</v-card-title
      >
    </v-container>

    <div
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
        margin-top: 15px;
      "
    >
      <div class="loader" v-show="studentsLoader">
        <div class="loaderItem"></div>
        <div class="loaderItem"></div>
        <div class="loaderItem"></div>
      </div>

      <v-slide-group>
        <v-slide-item v-for="student in students" :key="student.id">
          <v-card
            id="slideCard"
            @click="viewStudent(student)"
            style="
              margin: 2px 20px;
              display: flex;
              flex-direction: column;
              width: 260px;
              height: 300px;
              margin-bottom: 2px;
            "
          >
            <div>
              <v-img
                height="170px"
                contain
                :src="
                  ' https://api.acadletics.com/uploads/' + student.image.name
                "
              >
              </v-img>
            </div>

            <div>
              <div
                style="position: absolute; margin-top: -50px; margin-left: 65%"
              >
                <v-img
                  v-if="student.nomination.nominationType == 'Week'"
                  height="100px"
                  width="80px"
                  src="../assets/images/badge_week.png"
                ></v-img>
                <v-img
                  v-else-if="student.nomination.nominationType == 'Month'"
                  height="100px"
                  width="80px"
                  src="../assets/images/badge_month.png"
                ></v-img>
                <v-img
                  v-else
                  height="100px"
                  width="80px"
                  src="../assets/images/badge_year.png"
                ></v-img>
              </div>
              <h4
                style="
                  height: 35px;
                  line-height: 16px;
                  margin-top: 16px;
                  color: #3d3d3d;
                  font-size: 16px;
                  font-weight: bold;
                  padding: 0px 10px;
                "
              >
                {{ student.name }}
              </h4>
              <div
                style="
                  display: flex;
                  padding: 0px 10px;
                  margin-top: 8px;
                  height: 18px;
                  overflow: hidden;
                "
              >
                <v-icon small>school</v-icon>
                <h6 style="margin-left: 5px; color: #4169e1">
                  {{ student.school.name }}
                </h6>
              </div>
              <div style="display: flex; margin-top: 8px; padding: 0px 10px">
                <v-icon small>category</v-icon>
                <h6 style="margin-left: 5px">{{ student.category }}</h6>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: right;
                  margin-top: 10px;
                  margin-right: 10px;
                "
              >
                <h6 style="font-size: 10px">
                  {{
                    new Date(student.createdAt).getDate() +
                    '-' +
                    new Date(student.createdAt).getMonth() +
                    '-' +
                    new Date(student.createdAt).getFullYear()
                  }}
                </h6>
              </div>
            </div>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </div>

    <!-- PAST MAGAZINES -->
    <div
      style="
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #e6eaf1;
        padding-bottom: 30px;
      "
    >
      <v-container style="display: flex; justify-content: center">
        <v-card-title
          style="
            text-transform: uppercase;
            font-size: 30px;
            font-weight: bold;
            color: #3d3d3d;
          "
          >Past Magazines</v-card-title
        >
      </v-container>

      <div class="loader" v-show="magazineLoader">
        <div class="loaderItem"></div>
        <div class="loaderItem"></div>
        <div class="loaderItem"></div>
      </div>

      <v-slide-group>
        <v-slide-item v-for="magazine in magazines" :key="magazine.id">
          <v-card
            style="
              width: 260px;
              height: 300px;
              margin: 2px 18px;
              cursor: pointer;
            "
            @click="viewMagazine(magazine)"
          >
            <v-card-text>
              <v-img
                height="180px"
                width="100%"
                :src="
                  ' https://api.acadletics.com/uploads/' + magazine.image.name
                "
              >
              </v-img>
            </v-card-text>
            <div>
              <h4
                style="
                  margin: 0px 10px;
                  height: 40px;
                  line-height: 20px;
                  overflow: hidden;
                "
              >
                {{ magazine.name }}
              </h4>
              <h6 style="color: #4173a3; margin: 0px 10px; overflow: hidden">
                {{ magazine.type }}
              </h6>
              <div
                style="
                  display: flex;
                  justify-content: right;
                  margin-top: 8px;
                  margin-right: 15px;
                "
              >
                <h6 style="font-size: 10px">
                  {{
                    new Date(magazine.createdAt).getDate() +
                    '-' +
                    new Date(magazine.createdAt).getMonth() +
                    '-' +
                    new Date(magazine.createdAt).getFullYear()
                  }}
                </h6>
              </div>
            </div>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </div>

    <!-- Nomination Details -->
    <v-container id="mainNomination" style="display: flex; margin-top: 40px">
      <v-container
        id="nominationContent"
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <v-card-title
          style="
            font-size: 24px;
            color: #3d3d3d;
            font-weight: 600;
            text-transform: uppercase;
          "
          >Nomination Details</v-card-title
        >

        <v-card-subtitle style="margin-top: 8px; color: #646464"
          >Do you want to be featured on our platform? Submit the form below and
          we'll review your application.
        </v-card-subtitle>
        <v-container
          style="display: flex; flex-direction: column; align-items: center"
        >
          <v-img
            height="239px"
            width="239px"
            src="../assets/images/form.png"
          ></v-img>
          <router-link to="/submit-form" style="text-decoration: none">
            <v-btn
              rounded
              style="
                width: 260px;
                height: 60px;
                margin-top: 30px;
                font-size: 16px;
                font-weight: regular;
                color: white;
                background-color: var(--clr-primary);
              "
              >Submit form</v-btn
            >
          </router-link>
        </v-container>
      </v-container>
      <!-- <v-container id="nominationQA" style="width: 80%">
        <v-expansion-panels v-model="model">
          <v-expansion-panel
            v-for="(item, i) in items"
            :key="i"
            style="margin: 10px; padding: 17px; border-radius: 5px"
          >
            <v-expansion-panel-header
              id="nominationQues"
              style="color: #0c1125; font-weight: bold"
              >{{ item.question }}
            </v-expansion-panel-header>
            <v-expansion-panel-content
              id="nominationAns"
              style="font-size: 10px; color: #8d9598; font-weight: bold"
            >
              {{ item.answer }}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container> -->
    </v-container>

    <!-- Schools -->
    <v-main class="schoolBg">
      <div
        class="schoolsMain"
        style="display: flex; margin-top: 100px; padding: 0px 10px"
      >
        <v-card-text class="schoolContent" style="width: 40%">
          <div>
            <v-card-title
              style="font-size: 30px; font-weight: bold; color: white"
              >SCHOOLS</v-card-title
            >
            <v-card-subtitle
              style="font-size: 12px; color: white; margin-top: 0px"
              >Academics and Athletics is coordinating with the listed schools
              to look for talent. We strive our best to create a community where
              students' excellence gets recognized</v-card-subtitle
            >
          </div>
          <div>
            <router-link to="/schools" style="text-decoration: none">
              <v-btn
                rounded
                style="
                  margin-left: 18px;
                  background-color: var(--clr-primary);
                  color: white;
                  border: 1px solid white;
                "
              >
                Explore More
              </v-btn>
            </router-link>
          </div>
        </v-card-text>

        <v-card-text class="schoolSlider" style="display: flex; width: 60%">
          <div
            class="loader"
            v-show="schoolLoader"
            style="margin-top: 60px; margin-left: -25%; position: absolute"
          >
            <div class="loaderItem" style="border: 5px solid #fff"></div>
            <div class="loaderItem" style="border: 5px solid #fff"></div>
            <div class="loaderItem" style="border: 5px solid #fff"></div>
          </div>
          <v-slide-group style="margin-top: 20px">
            <v-slide-item
              v-for="school in schools"
              :key="school.id"
              style="margin-left: 10px"
            >
              <v-card
                style="margin: 0px 10px 0px 0px"
                width="300px"
                height="160px"
              >
                <div
                  style="
                    background-color: #ddd;
                    width: 100%;
                    height: 50%;
                    overflow: hidden;
                  "
                >
                  <v-card-title
                    style="
                      font-size: 20px;
                      line-height: 27px;
                      font-weight: 500;
                      word-break: normal;
                    "
                    >{{ school.name }}</v-card-title
                  >
                </div>

                <div
                  style="
                    display: flex;
                    padding: 0px 20px;
                    align-items: center;
                    margin-top: 20px;
                  "
                >
                  <v-btn
                    @click="schoolWebsite(school.website)"
                    small
                    style="
                      margin: 10px 0px;
                      color: white;
                      background-color: #4173a3;
                    "
                  >
                    <v-icon small style="margin-right: 5px" color="white"
                      >mdi-web</v-icon
                    >
                    Website</v-btn
                  >
                  <v-btn
                    @click="schoolStudents(school._id)"
                    small
                    style="
                      margin: 10px 0px;
                      color: white;
                      background-color: #4173a3;
                      margin-left: 24px;
                    "
                  >
                    <v-icon small style="margin-right: 5px" color="white"
                      >mdi-account-group</v-icon
                    >
                    Students</v-btn
                  >
                </div>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-card-text>
      </div>
    </v-main>

    <!-- TESTIMONIALS -->
    <!-- <v-main style="background-color: #e6eaf1">
      <v-container
        style="
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 20px;
        "
      >
        <v-card-title style="color: #3d3d3d; font-size: 30px; font-weight: bold"
          >TESTIMONIALS</v-card-title
        >

        <v-container style="display: flex; justify-content: center">
          <iframe
            id="testVidio"
            width="700"
            height="350"
            src="https://www.youtube.com/embed/2gqgSmsA_Oo"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <v-img
            id="testImg"
            style="position: absolute; right: 0px; bottom: 0px; width: 260px"
            src="../assets/images/poll.png"
          ></v-img>
        </v-container>
      </v-container>
    </v-main> -->

    <!-- Acadletics App -->
    <v-container id="acadApp" style="display: flex; margin-top: 20px">
      <v-card-text id="acadAppContent" style="width: 50%; margin-top: 40px">
        <div id="appText">
          <h2
            class="appTitle"
            style="color: #3d3d3d; font-weight: bold; font-size: 35px"
          >
            Academics & Athletics
          </h2>
          <div class="appSubtitle">
            <p style="color: #646464; margin-top: 30px; width: 130%">
              We have also developed an app that would be used to download
              magazines and highlight students' achievements. The app would
              allow parents and academics, and athletic directors to submit
              students' data so they get recognized. This would be a great way
              to motivate students and create a healthy competition.
            </p>
          </div>
        </div>

        <div id="appRow" style="display: flex; padding: 40px 0px 0px 60px">
          <v-card
            id="appBtn"
            style="width: 180px; padding: 10px 20px 10px 20px"
          >
            <a
              href="https://apps.apple.com/us/app/academics-and-athletics/id1632715168"
            >
              <v-img width="100%" src="../assets/images/Image 7.png"></v-img>
            </a>
          </v-card>
          <v-card
            style="
              width: 180px;
              margin-left: 40px;
              padding: 10px 20px 10px 20px;
            "
          >
            <a
              href="https://play.google.com/store/apps/details?id=com.sparkosol.acadletics&hl=en&gl=US"
            >
              <v-img width="100%" src="../assets/images/Image 6.png"></v-img>
            </a>
          </v-card>
        </div>
      </v-card-text>
      <v-card-text id="acadAppImg" style="width: 50%; margin-left: 220px">
        <v-img
          id="appImg"
          style="width: 300px; height: 380px"
          src="../assets/images/mobile3.png"
        ></v-img>
      </v-card-text>
    </v-container>

    <!-- Home Footer -->
    <div class="homeFooter">
      <div class="homeFooter-content1">
        <div class="homeFooter-img">
          <v-img src="../assets/images/Academics white.png" alt="logo"></v-img>
        </div>

        <div class="homeFooter-socialIcons">
          <router-link to="" class="router-link">
            <v-img
              class="socialIcons-vImg"
              src="../assets/images/facebook.png"
              alt="icon"
            ></v-img>
          </router-link>
          <router-link to="" class="router-link">
            <v-img
              class="socialIcons-vImg"
              src="../assets/images/instagram.png"
              alt="icon"
            ></v-img>
          </router-link>
          <router-link to="" class="router-link">
            <v-img
              class="socialIcons-vImg"
              src="../assets/images/twitter.png"
              alt="icon"
            ></v-img>
          </router-link>
          <router-link to="" class="router-link">
            <v-img
              class="socialIcons-vImg"
              src="../assets/images/linkedin.png"
              alt="icon"
            ></v-img>
          </router-link>
        </div>
      </div>

      <div class="homeFooter-content2">
        <div class="homeFooter-column1">
          <!-- <v-divider class="homeFooter-divider"></v-divider> -->
          <div class="homeFooter-divider1"></div>
          <ul>
            <li>
              <router-link class="router-link" to="/magazines"
                >Magazines</router-link
              >
            </li>
            <li>
              <router-link class="router-link" to="/students"
                >Students</router-link
              >
            </li>
            <li>
              <router-link class="router-link" to="/terms"
                >Terms & Conditions</router-link
              >
            </li>
            <li>
              <router-link class="router-link" to="/policy"
                >Privacy / Cookies</router-link
              >
            </li>
          </ul>
        </div>

        <div class="homeFooter-column2">
          <!-- <v-divider class="homeFooter-divider"></v-divider> -->
          <div class="homeFooter-divider2"></div>
          <ul>
            <li>
              <router-link class="router-link" to="/schools"
                >Schools</router-link
              >
            </li>
            <li>
              <router-link class="router-link" to="/about"
                >About us</router-link
              >
            </li>
            <li>
              <router-link class="router-link" to="/contact"
                >Contact</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      magazineLoader: true,
      schoolLoader: true,
      studentsLoader: true,
      sponserLoader: true,
      sponserContent: true,
      magazines: null,
      students: null,
      schools: null,
      sponsers: null,
      moddel: null,
      model: [],
      items: [
        {
          question:
            'Do I need to confirm in advance if travelling on a different train?',
          answer:
            "You don't need to let us know if you want to change trains, but you'ill need to check the terms of your ticket to see if it'll still be valid for a different train."
        },
        {
          question: 'How much luggage can I take on the train?',
          answer:
            "You don't need to let us know if you want to change trains, but you'ill need to check the terms of your ticket to see if it'll still be valid for a different train."
        },
        {
          question: 'How will I receive my tickets?',
          answer:
            "You don't need to let us know if you want to change trains, but you'ill need to check the terms of your ticket to see if it'll still be valid for a different train."
        },
        {
          question: 'What do the different seat options mean?',
          answer:
            "You don't need to let us know if you want to change trains, but you'ill need to check the terms of your ticket to see if it'll still be valid for a different train."
        }
      ]
    };
  },
  methods: {
    async loadMagazines() {
      await axios
        .get('/magazine')
        .then((result) => {
          if (result.data.length > 0) {
            this.magazineLoader = false;
            this.magazines = result.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async loadStudents() {
      await axios
        .get('/student/status/Nominated')
        .then((result) => {
          if (result.data.length > 0) {
            this.studentsLoader = false;
            this.students = result.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async loadSchools() {
      await axios
        .get('/school')
        .then((result) => {
          if (result.data.length > 0) {
            this.schoolLoader = false;
            this.schools = result.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async loadSponsers() {
      await axios
        .get('/sponsor')
        .then((result) => {
          if (result.data.length > 0) {
            this.sponserLoader = false;
            this.sponsers = result.data;
          } else {
            this.sponserContent = false;
          }
        })
        .catch((error) => {
          console.log(error);
          this.sponserContent = false;
        });
    },

    viewMagazine(item) {
      this.$router.push(`/magazines/${item._id}`);
    },

    viewStudent(item) {
      this.$router.push(`/students/${item._id}`);
    },

    viewSchool(item) {
      this.$router.push(`/schools/${item._id}`);
    },

    downloadMagazine() {
      let url =
        'https://api.acadletics.com/uploads/' + this.magazines[0].file.name;
      window.location.href = url;
    },
    schoolWebsite(e) {
      let url;
      if (e.includes('http')) url = e;
      else url = 'https://' + e;
      window.open(url);
    },
    schoolStudents(item) {
      this.$router.push(`/schools/${item}/students`);
    },
    sponsorLink(e) {
      let url;
      if (e.includes('http')) url = e;
      else url = 'https://' + e;
      window.open(url);
    }
  },
  mounted() {
    try {
      this.$OneSignal
        .init({
          appId: '02f08ccb-bc46-4d21-83cd-c69f2ad4c60b',
          safari_web_id:
            'web.onesignal.auto.3b8b9214-66ac-44d1-a7fb-a9dc856242cb',
          promptOptions: {
            siteName: 'Acadletics',
            actionMessage:
              "We'd like to show you notifications for the latest updates.",
            exampleNotificationTitle: 'New Review',
            exampleNotificationMessage:
              'User johndoa123@gmail.com  has received a review.',
            exampleNotificationCaption: 'You can unsubscribe anytime',
            acceptButtonText: 'ALLOW',
            cancelButtonText: 'NO THANKS',
            autoAcceptTitle: 'Click Allow',
            slidedown: {
              prompts: [
                {
                  type: 'push',
                  autoPrompt: true,
                  text: {
                    actionMessage:
                      "We'd like to show you notifications for the latest updates.",
                    acceptButton: 'Allow',
                    cancelButton: 'No Thanks'
                  },
                  delay: {
                    pageViews: 1,
                    timeDelay: 5
                  }
                }
              ]
            }
          },
          welcomeNotification: {
            disable: false,
            title: 'Welcome to Acadletics',
            message: 'Thanks for subscribing. Now you can stay up to date.'
          },
          notifyButton: {
            enable: true,
            size: 'medium',
            showCredit: false
          },
          subdomainName: 'rr'
        })
        .then(async () => {
          await this.$OneSignal.sendTag('isCustomer', true, () => {
            // console.log('isCustomer set');
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
    this.loadMagazines();
    this.loadStudents();
    this.loadSchools();
    this.loadSponsers();
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

.headerBtnRow {
  margin: 50px 0px 0px 0px;
  display: flex;
  padding: 0px 50px;
  justify-content: space-between;
  width: 85%;
}

.appleBtn,
.playstoreBtn {
  border: 1px solid white;
  display: flex;
  height: 50px;
  width: 160px;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--clr-primary);
}

.appleBtnImg {
  width: 40%;
  display: flex;
  align-items: center;
  margin-right: -10px;
}

.appleBtnContent {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.appleBtn,
.playstoreBtn {
  transition-duration: 0.2s;
  transition-delay: 0s;
}

.appleBtn:hover,
.playstoreBtn:hover {
  transform: scale(1.04);
}

#sponsers {
  transition-duration: 0.2s;
  transition-delay: 0s;
}

#sponsers:hover {
  transform: scale(1.1);
}

.loader {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loaderItem {
  border-radius: 100%;
  border: 5px solid #4173a3;
  margin: calc(10px);
}

.loaderItem:nth-child(1) {
  animation: loader 0.6s ease-in-out alternate infinite;
}

.loaderItem:nth-child(2) {
  animation: loader 0.6s ease-in-out alternate 0.2s infinite;
}

.loaderItem:nth-child(3) {
  animation: loader 0.6s ease-in-out alternate 0.4s infinite;
}

@keyframes loader {
  100% {
    transform: scale(2);
  }
}

.homeHeaderBG {
  background: url('../assets/images/Group 133.png') no-repeat center center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
}

.homeHeader {
  display: flex;
  height: 526px;
}

.homeHeader-content1 {
  width: 50%;
  margin-top: 120px;
  padding: 0px 50px;
}

.homeHeader-content1-title {
  font-size: 30px;
  font-weight: 700;
  color: white;
  word-break: normal;
  overflow-wrap: break-word;
}

.homeHeader-content1-subtitle {
  font-size: 15px;
  line-height: 28px;
  color: white;
  margin-top: 8px !important;
}

.homeHeader-content2 {
  width: 50%;
  display: flex;
}

.homeHeader-football {
  width: 100px;
  position: absolute;
  margin: 70px 0px 0px 0px;
  margin-left: 11%;
}

.homeHeader-man {
  height: 560px;
  margin-bottom: -15px;
}

.homeHeader-seek {
  width: 180px;
  height: 160px;
  position: absolute;
  margin: 38px 0px 0px 0px;
  margin-left: 28%;
}

.schoolBg {
  margin-top: 50px;
  background: url('../assets/images/Intersection 1.png') no-repeat center center;
  height: 400px;
  width: 100%;
  top: 0;
  left: 0;
  background-size: cover;
}

/* HOME FOOTER */
.homeFooter {
  background-color: #4173a3;
  display: flex;
  padding: 30px;
}

.homeFooter-img {
  width: 180px;
}

.homeFooter-content1,
.homeFooter-content2 {
  display: flex;
  align-items: center;
}

.homeFooter-content1 {
  width: 40%;
  justify-content: center;
  flex-direction: column;
}

.homeFooter-socialIcons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.homeFooter-socialIcons v-img {
  display: none !important;
}

.socialIcons-vImg {
  width: 30px;
  margin: 0px 8px;
}

.homeFooter-content2 {
  width: 60%;
  justify-content: left;
}

.homeFooter-divider1,
.homeFooter-divider2 {
  border: 0.1px solid white;
  background-color: white;
  margin-right: -10px;
}

.homeFooter-column1,
.homeFooter-column2 {
  display: flex;
  margin: 0px 40px;
}

.homeFooter-column1 ul li,
.homeFooter-column2 ul li {
  list-style: none;
  margin: 10px 0px;
  color: white;
  font-size: 14px;
}

/* MIN WIDTH */
@media only screen and (min-width: 1350px) {
  .schoolBg {
    height: 500px;
  }
}

@media screen and (min-width: 1730px) {
  .schoolBg {
    height: 600px;
  }

  .schoolsMain {
    margin-top: 200px !important;
  }
}

@media screen and (min-width: 2100px) {
  .schoolBg {
    background-color: #4173a3;
  }
}

/* MAX WIDTH */
@media only screen and (max-width: 1264px) {
  #mainSchools {
    height: 480px;
  }

  .schoolBg {
    height: 600px;
  }

  .schoolsMain {
    flex-direction: column;
    margin-top: 50px !important;
  }

  .schoolContent {
    width: 50% !important;
  }

  .schoolSlider {
    width: 95% !important;
    justify-content: right;
  }
}

@media only screen and (max-width: 1250px) {
  .homeHeader-football {
    margin-left: 8%;
  }

  .headerBtnRow {
    width: 100%;
  }
}

@media only screen and (max-width: 1160px) {
  #ourStars-card1,
  #ourStars-card2 {
    flex-direction: column;
    justify-content: space-between;
  }

  #stars-card1 {
    margin: 0px 0px 20px 30px;
  }

  #stars-card1,
  #stars-card2,
  #stars-card3,
  #stars-card4 {
    height: 240px;
  }

  #appImg {
    margin-left: -30%;
  }
}

@media only screen and (max-width: 1100px) {
  .homeHeader-content1 {
    margin-top: 50px;
  }

  .headerBtnRow {
    padding: 0px 20px;
  }
}

@media only screen and (max-width: 960px) {
  .homeHeader-content1-title {
    font-size: 24px;
  }

  .homeHeader-content1-subtitle {
    margin-top: -10px !important;
    font-size: 13px;
    line-height: 24px;
  }

  .headerBtnRow {
    width: 120%;
    margin-top: 20px;
  }

  .schoolBg {
    height: 650px;
  }

  .schoolContent {
    width: 60% !important;
  }

  #acadApp {
    flex-direction: column;
    align-items: center;
  }

  #acadAppImg {
    display: none;
  }

  #appText {
    width: 140%;
    margin-left: -130px;
  }

  #appRow {
    margin-left: -15%;
  }
}

@media only screen and (max-width: 930px) {
  .homeHeader {
    height: 446px;
  }

  .homeHeader-seek {
    width: 150px;
    height: 150px;
    margin-top: 46px;
  }

  .homeHeader-man {
    height: 480px;
  }
}

@media only screen and (max-width: 860px) {
  .homeHeader {
    height: 366px;
  }

  .homeHeader-seek {
    width: 120px;
    height: 120px;
    margin-left: 30%;
  }

  .homeHeader-football {
    width: 80px;
    margin-top: 60px;
  }

  .homeHeader-man {
    height: 400px;
  }

  .homeHeader-content1-title {
    font-size: 20px;
    padding: 0px;
  }

  .homeHeader-content1-subtitle {
    margin-top: 6px !important;
    font-size: 12px;
    line-height: 22px;
    padding: 0px;
  }

  .homeHeader-content1-btnRow {
    margin-top: -2px;
    display: none !important;
  }

  .headerBtn {
    margin: 14px 0px !important;
  }

  .headerBtnRow {
    padding: 0px;
    margin: 30px 0px;
  }

  #mainNomination {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  #nominationContent {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .schoolSlider {
    padding: 0px !important;
    margin: 0px !important;
    margin-top: 25px !important;
  }
}

@media only screen and (max-width: 800px) {
  #nominationQA {
    width: 100% !important;
  }
  .schoolSlider {
    width: 100% !important;
  }
}

@media only screen and (max-width: 780px) {
  .homeHeader-content1 {
    margin-top: 20px;
  }

  .homeHeader-content1-subtitle {
    width: 120%;
  }

  .headerBtnRow {
    width: 130%;
  }
}

@media only screen and (max-width: 700px) {
  .homeHeader-content2 {
    display: none;
  }

  .homeHeader-content1 {
    width: 100%;
    padding: 0px 20px;
    margin-top: 50px;
  }

  .homeHeader-content1-title {
    font-size: 30px;
    font-weight: 700;
    color: white;
  }

  .homeHeader-content1-subtitle {
    font-size: 14px;
    line-height: 30px;
    color: white;
    width: 100%;
  }

  .headerBtnRow {
    width: 70%;
  }

  #appRow {
    margin-left: -140px;
  }

  .appTitle {
    font-size: 24px !important;
  }

  .homeFooter {
    flex-direction: column;
  }

  .homeFooter-content1,
  .homeFooter-content2 {
    width: 100%;
  }

  .homeFooter-content2 {
    margin-top: 50px;
    justify-content: center;
  }
}

@media only screen and (max-width: 680px) {
  .appTitle {
    font-size: 10px;
    line-height: 35px;
  }

  #appRow {
    margin-left: -130px;
  }
}

@media only screen and (max-width: 650px) {
  #starsMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: -30px;
  }

  #ourStars-card2 {
    margin: 20px 0px;
  }

  #stars-card4 {
    margin-top: 100px;
  }

  #stars-card1,
  #stars-card3 {
    width: 340px;
  }
}

@media only screen and (max-width: 600px) {
  .schoolBg {
    height: 720px;
    margin-top: -80px;
  }

  .schoolContent {
    margin-top: 50px;
    width: 80% !important;
  }

  #testVidio {
    height: 200px;
    width: 95%;
  }

  #testImg {
    display: none;
  }

  #appRow {
    margin-left: -50%;
  }

  #appRow {
    flex-direction: column;
    align-items: center;
  }

  #appBtn {
    margin-bottom: 20px;
    margin-left: 40px;
  }
}

@media only screen and (max-width: 560px) {
  #schImg {
    margin-left: 35px !important;
  }
}

@media only screen and (max-width: 500px) {
  .headerBtnRow {
    width: 100%;
  }

  #nominationQues {
    padding: 0;
    margin: 0;
    font-size: 12px;
  }

  #nominationAns {
    font-size: 9px !important;
    width: 110%;
    margin-left: -24px;
  }

  .appSubtitle {
    margin-top: -10px;
    font-size: 12px;
  }

  #appText {
    margin-left: -50%;
    width: 160%;
  }
}

@media only screen and (max-width: 480px) {
  .homeHeader {
    align-items: center;
    height: 420px;
  }

  .homeHeader-content1-title {
    margin-top: 30px;
  }

  .schoolContent {
    width: 90% !important;
  }

  #appRow {
    margin-left: -80%;
  }

  .homeFooter-content2 {
    justify-content: space-between;
    padding: 0px 10px;
  }

  .homeFooter-column1,
  .homeFooter-column2 {
    margin: 10px 10px 30px 10px;
  }

  .homeFooter {
    padding: 20px 0px;
  }
}

@media only screen and (max-width: 400px) {
  .homeHeader {
    height: 550px;
  }

  .headerBtnRow {
    flex-direction: column;
    align-items: center;
  }

  .appleBtn {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 380px) {
  .schoolBg {
    height: 780px;
  }

  .schoolContent {
    /* usman */
    margin-top: 70px;
    width: 100% !important;
  }
}

@media only screen and (max-width: 350px) {
  .homeHeader {
    height: 360px;
  }

  .homeHeader-content1-title {
    font-size: 18px;
  }

  .homeHeader-content1-subtitle {
    font-size: 10px;
    line-height: 20px;
  }
}
</style>
