<template>
  <div>
    <v-card
      id="main"
      v-show="content"
      class="mx-auto pa-md-6"
      max-width="600"
      style="margin: 30px 0px"
    >
      <div class="vMain">
        <div style="height: 200px">
          <v-img
            contain
            width="100%"
            height="100%"
            :src="' https://api.acadletics.com/uploads/' + this.student.name"
            alt="image"
          />
        </div>
        <div class="content" style="margin: 0px 30px">
          <h4
            style="
              font-size: 25px;
              text-align: center;
              margin-top: 20px;
              color: #2f5e8b;
            "
          >
            {{ this.student.title }}
          </h4>
          <div style="margin: 20px 0px; margin-left: -20px">
            <div style="display: flex; margin: 10px 0px">
              <h2 class="deatailsTitle">Bio</h2>
              <h2 class="detailsText">{{ this.student.bio }}</h2>
            </div>

            <div style="display: flex; margin: 10px 0px">
              <h2 class="deatailsTitle">Category</h2>
              <h2 class="detailsText">{{ this.student.category }}</h2>
            </div>

            <div
              v-if="student.subject != 'null' && student.subject != ''"
              style="display: flex; margin: 10px 0px"
            >
              <h2 class="deatailsTitle">Subject Name</h2>
              <h2 class="detailsText">{{ this.student.subject }}</h2>
            </div>

            <div
              v-if="student.sport != 'null' && student.sport != ''"
              style="display: flex; margin: 10px 0px"
            >
              <h2 class="deatailsTitle">Sport Name</h2>
              <h2 class="detailsText">{{ this.student.sport }}</h2>
            </div>

            <div style="display: flex; margin: 10px 0px">
              <h2 class="deatailsTitle">Subject for Honor</h2>
              <h2 class="detailsText">{{ this.student.honor }}</h2>
            </div>

            <div style="display: flex; margin: 10px 0px">
              <h2 class="deatailsTitle">Favorite Hobbies</h2>
              <h2 class="detailsText">{{ this.student.favoriteHobbies }}</h2>
            </div>

            <div style="display: flex; margin: 10px 0px">
              <h2 class="deatailsTitle">Favorite Movies</h2>
              <h2 class="detailsText">{{ this.student.favoriteMovies }}</h2>
            </div>

            <div style="display: flex; margin: 10px 0px">
              <h2 class="deatailsTitle">Favorite Song</h2>
              <h2 class="detailsText">{{ this.student.favoriteSong }}</h2>
            </div>

            <div style="display: flex; margin: 10px 0px">
              <h2 class="deatailsTitle">Favorite TvShow</h2>
              <h2 class="detailsText">{{ this.student.favoriteTvShow }}</h2>
            </div>

            <div style="display: flex; margin: 10px 0px">
              <h2 class="deatailsTitle">Favorite Food</h2>
              <h2 class="detailsText">{{ this.student.favoriteFood }}</h2>
            </div>

            <div style="display: flex; margin: 10px 0px">
              <h2 class="deatailsTitle">Vacation Spot</h2>
              <h2 class="detailsText">{{ this.student.vacationSpot }}</h2>
            </div>

            <div style="display: flex; margin: 10px 0px; padding-bottom: 20px">
              <h2 class="deatailsTitle">Future Plan</h2>
              <h2 class="detailsText">{{ this.student.futurePlan }}</h2>
            </div>
            <v-divider></v-divider>
            <div style="margin: 10px 0px; padding-bottom: 20px">
              <h2 class="deatailsTitle">Summary</h2>
              <h2
                style="
                  font-size: 16px;
                  width: 100%;
                  margin-top: 10px;
                  font-weight: 500;
                  word-break: break-word;
                "
              >
                {{ this.student.summary }}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </v-card>

    <v-card
      v-show="loader"
      id="loader"
      class="mx-auto pa-md-6"
      max-width="600"
      style="margin: 30px 0px"
    >
      <v-col>
        <v-skeleton-loader type="image"> </v-skeleton-loader>
      </v-col>
      <v-col>
        <v-skeleton-loader style="margin-left: 35%" type="heading">
        </v-skeleton-loader>
      </v-col>
      <div style="display: flex; justify-content: space-between">
        <div>
          <v-col v-for="i in 5" :key="i">
            <v-skeleton-loader class="textLoader" width="200" type="text">
            </v-skeleton-loader>
          </v-col>
        </div>

        <div>
          <v-col v-for="i in 5" :key="i">
            <v-skeleton-loader
              class="textLoader"
              style="margin-right: 70px"
              width="200"
              type="text"
            >
            </v-skeleton-loader>
          </v-col>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      content: false,
      loader: true,
      student: [
        {
          title: null,
          createdAt: null,
          bio: null,
          category: null,
          honor: null,
          favoriteHobbies: null,
          favoriteMovies: null,
          favoriteSong: null,
          favoriteTvShow: null,
          favoriteFood: null,
          futurePlan: null,
          vacationSpot: null,
          summary: null,
          subject: null,
          sport: null
        }
      ]
    };
  },
  methods: {
    async loadData() {
      await axios
        .get('student/' + this.$route.params.id)
        .then((result) => {
          this.student.name = result.data.image.name;
          this.student.title = result.data.name;
          this.student.bio = result.data.bio;
          this.student.category = result.data.category;
          this.student.honor = result.data.honor;
          this.student.favoriteHobbies = result.data.favoriteHobbies;
          this.student.favoriteMovies = result.data.favoriteMovies;
          this.student.favoriteSong = result.data.favoriteSong;
          this.student.favoriteTvShow = result.data.favoriteTvShow;
          this.student.favoriteFood = result.data.favoriteFood;
          this.student.futurePlan = result.data.futurePlan;
          this.student.vacationSpot = result.data.vacationSpot;
          this.student.summary = result.data.summary;
          this.student.subject = result.data.subject;
          this.student.sport = result.data.sport;
          this.loader = false;
          this.content = true;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  mounted() {
    this.loadData();
  }
};
</script>


<style scoped>
#loader {
  margin: 50px 0px;
}

.deatailsTitle {
  font-size: 16px;
  color: #2f5e8b;
  width: 50%;
}

.detailsText {
  font-size: 16px;
  font-weight: 500;
  width: 50%;
}

@media screen and (max-width: 800px) {
  .textLoader {
    width: 100px !important;
  }
  .deatailsTitle,
  .detailsText {
    font-size: 12px;
  }

  #loader {
    margin: 100px 0px;
  }

  #main {
    width: 90%;
    margin: 60px 20px !important;
  }

  .vMain {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .content {
    width: 90% !important;
  }

  .content h4 {
    margin-top: 24px;
  }

  #loader {
    width: 90%;
  }
}
</style>