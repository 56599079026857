import Vue from 'vue';
import VueRouter from 'vue-router';
import Dashboard from './components/Dashboard';
import NotFound from './views/404';
import Home from './views/home';
import Magazines from './views/Magazines/Magazines';
import MagazineView from './views/Magazines/MagazineView';
import Students from './views/Students/Students';
import StudentView from './views/Students/StudentView';
import Schools from './views/Schools/Schools';
import SchoolView from './views/Schools/SchoolView';
import SchoolStudents from './views/Schools/SchoolStudents'
import About from './views/About';
import Contact from './views/Contact';
import SubmitForm from './views/SubmitForm';
import Terms from './views/Terms'
import Policy from './views/Policy'

Vue.use(VueRouter);

const routes = [
    {
        path: '*',
        component: NotFound,
    },
    {
        path: '/',
        component: Dashboard,
        children: [{
            name: 'home',
            path: '/',
            component: Home
        },
        {
            name: 'magazine',
            path: '/magazines',
            component: Magazines
        },
        {
            name: 'magazineId',
            path: '/magazines/:id',
            component: MagazineView
        },
        {
            name: 'student',
            path: '/students',
            component: Students
        },
        {
            name: 'studentId',
            path: '/students/:id',
            component: StudentView
        },
        {
            name: 'school',
            path: '/schools',
            component: Schools
        },
        {
            name: 'schoolId',
            path: '/schools/:id',
            component: SchoolView
        },
        {
            name: 'schoolStudents',
            path: '/schools/:id/students',
            component: SchoolStudents
        },
        {
            name: 'about',
            path: '/about',
            component: About
        },
        {
            name: 'contact',
            path: '/contact',
            component: Contact
        },
        {
            name: 'SubmitForm',
            path: '/submit-form',
            component: SubmitForm,
        },
        {
            name: 'terms',
            path: '/terms',
            component: Terms,
        },
        {
            name: 'policy',
            path: '/policy',
            component: Policy,
        }
        ],

    }];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    }
});

export default router;