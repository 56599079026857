import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import axios from "axios";
import OneSignalVue from 'onesignal-vue'
import VueCarousel from 'vue-carousel';
import './assets/style.css'

Vue.config.productionTip = false;

// axios.defaults.baseURL = 'http://10.20.20.83:10000/'
// axios.defaults.baseURL = 'https://api-acadletics.postredi.com/'
axios.defaults.baseURL = 'https://api.acadletics.com/'

Vue.use(VueCarousel);
Vue.use(OneSignalVue);
new Vue({
    vuetify,
    router,
    render: (h) => h(App)
}).$mount('#app');