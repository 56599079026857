<template>
  <div class="Main">
    <div class="Card" v-show="content">
      <v-card style="width: 100%; height: 200px">
        <v-img
          contain
          width="100%"
          height="100%"
          :src="' https://api.acadletics.com/uploads/' + this.school.name"
          alt="image"
        />
      </v-card>
      <div class="content">
        <h4 style="font-size: 25px; margin-top: 15px">
          {{ this.school.title }}
        </h4>
        <p style="color: #4173a3">{{ this.school.address }}</p>
        <p style="margin-top: -10px">
          <span>{{ this.school.city }}</span
          >,
          <span>{{ this.school.state }}</span>
        </p>
        <v-col style="margin-top: -10px" class="d-flex justify-center">
          <div style="margin: 0px 10px">
            <v-btn @click="website" dark color="#4173A3">
              <v-icon style="margin-right: 5px" color="white">mdi-web</v-icon>
              Website
            </v-btn>
          </div>

          <div style="margin: 0px 10px">
            <v-btn @click="students" dark color="#4173A3">
              <v-icon style="margin-right: 5px" color="white"
                >mdi-account-group</v-icon
              >
              Students
            </v-btn>
          </div>
        </v-col>
        <p style="font-size: 14px; margin-top: 10px; word-break: break-word">
          {{ this.school.description }}
        </p>
      </div>
    </div>

    <div v-show="loader" class="Card" style="padding: 10px 0px">
      <v-skeleton-loader type="image" />
      <v-skeleton-loader type="heading" style="margin-top: 15px" />
      <v-skeleton-loader type="table-cell" style="margin-top: -4px" />
      <v-skeleton-loader type="table-cell" style="margin-top: -24px" />
      <div
        class="btnLoader"
        style="display: flex; justify-content: space-between; margin: 0px 35%"
      >
        <v-skeleton-loader type="button" />
        <v-skeleton-loader type="button" />
      </div>
      <v-skeleton-loader type="paragraph" style="margin-top: 30px" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      content: false,
      loader: true,
      school: [
        {
          title: '',
          name: '',
          address: '',
          city: '',
          state: '',
          website: '',
          description: ''
        }
      ]
    };
  },
  methods: {
    async loadData() {
      await axios
        .get('school/' + this.$route.params.id)
        .then((result) => {
          this.school.name = result.data.image.name;
          this.school.title = result.data.name;
          this.school.address = result.data.address;
          this.school.city = result.data.city;
          this.school.state = result.data.state;
          this.school.website = result.data.website;
          this.school.description = result.data.description;
          this.loader = false;
          this.content = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    website() {
      let url;
      if (this.school.website.includes('http')) url = this.school.website;
      else url = 'https://' + this.school.website;
      window.location.href = url;
    },

    students() {
      this.$router.push(`/schools/${this.$route.params.id}/students`);
    }
  },
  mounted() {
    this.loadData();
  }
};
</script>

<style>
.Main {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Card {
  margin: 50px 0px;
  width: 50%;
}

@media screen and (max-width: 800px) {
  .Card {
    width: 80%;
  }

  .btnLoader {
    margin: 20px 20% 0 20% !important;
  }
}

@media screen and (max-width: 400px) {
  .Card {
    width: 90%;
  }
}
</style>