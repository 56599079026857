<template>
  <div>
    <div style="padding: 20px">
      <div style="height: 100px; width: 100%"></div>
    </div>

    <div class="formMain">
      <v-form class="form" @submit.prevent="submit" ref="form" lazy-validation>
        <div style="text-align: center">
          <h2 style="font-size: 40px">Submit Form</h2>
        </div>

        <div class="formLineMain">
          <div class="formLine">
            <v-col>
              <h4>You are applying as:</h4>
              <v-radio-group
                :rules="[(v) => !!v || 'Type is required']"
                v-model="formData.type"
              >
                <v-radio
                  v-for="radio in radios"
                  :key="radio"
                  :label="radio"
                  :value="radio"
                >
                </v-radio>
              </v-radio-group>
            </v-col>

            <v-col>
              <h4>Add Image:</h4>
              <v-file-input
                :rules="[(v) => !!v || 'Image is required']"
                v-model="formData.image"
                style="margin-top: 15px"
                accept="image/*"
                label="Image"
              >
              </v-file-input>
            </v-col>

            <v-col>
              <v-text-field
                :rules="[(v) => !!v || 'Name is required']"
                v-model="formData.name"
                type="text"
                label="Name"
                placeholder="Enter Name"
                outlined
              >
              </v-text-field>
            </v-col>

            <v-col>
              <v-select
                :rules="[(v) => !!v || 'Category is required']"
                v-model="formData.category"
                outlined
                :items="categories"
                label="Category"
                @change="categoryType"
              >
              </v-select>
            </v-col>

            <v-col v-show="academic">
              <v-text-field
                :rules="[(v) => !!v || 'Subject is required']"
                v-model="formData.subject"
                type="text"
                label="Subject"
                placeholder="Enter Subject Name"
                outlined
              >
              </v-text-field>
            </v-col>

            <v-col v-show="sport">
              <v-text-field
                :rules="[(v) => !!v || 'Sport is required']"
                v-model="formData.sport"
                type="text"
                label="Sport"
                placeholder="Enter Sport Name"
                outlined
              >
              </v-text-field>
            </v-col>

            <v-col>
              <v-select
                v-model="formData.school"
                outlined
                :items="schools"
                item-text="name"
                item-value="_id"
                label="Schools"
                :loading="schoolLoading"
              >
                <template v-slot:prepend-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-text-field
                        v-model="searchTerm"
                        placeholder="Search"
                        @input="searchSchools"
                        outlined
                      ></v-text-field>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                </template>
              </v-select>
            </v-col>

            <v-col>
              <v-textarea
                :rules="[(v) => !!v || 'Summary is required']"
                v-model="formData.summary"
                outlined
                label="Summary"
                placeholder="Description"
              >
              </v-textarea>
            </v-col>

            <v-col>
              <v-textarea
                :rules="[(v) => !!v || 'Bio is required']"
                v-model="formData.bio"
                outlined
                label="Bio"
                placeholder="Enter Bio"
              >
              </v-textarea>
            </v-col>
          </div>

          <div class="formLine">
            <v-col>
              <v-text-field
                :rules="[(v) => !!v || 'Subject for Honor is required']"
                v-model="formData.honor"
                type="text"
                label="Subject for Honor"
                placeholder="Enter Subject for Honor"
                outlined
              >
              </v-text-field>
            </v-col>

            <v-col>
              <v-text-field
                :rules="[(v) => !!v || 'Hobby is required']"
                v-model="formData.hobby"
                type="text"
                label="Favorite Hobby"
                placeholder="Enter Favorite Hobby"
                outlined
              >
              </v-text-field>
            </v-col>

            <v-col>
              <v-text-field
                :rules="[(v) => !!v || 'Movie is required']"
                v-model="formData.movie"
                type="text"
                label="Favorite Movie"
                placeholder="Enter Favorite Movie"
                outlined
              >
              </v-text-field>
            </v-col>

            <v-col>
              <v-text-field
                :rules="[(v) => !!v || 'Song is required']"
                v-model="formData.song"
                type="text"
                label="Favorite Song"
                placeholder="Enter Favorite Song"
                outlined
              >
              </v-text-field>
            </v-col>

            <v-col>
              <v-text-field
                :rules="[(v) => !!v || 'TV Show is required']"
                v-model="formData.tvShow"
                type="text"
                label="Favorite Tv Show"
                placeholder="Enter Favorite Tv Show"
                outlined
              >
              </v-text-field>
            </v-col>

            <v-col>
              <v-text-field
                :rules="[(v) => !!v || 'Favorite Food is required']"
                v-model="formData.food"
                type="text"
                label="Favorite Food"
                placeholder="Enter Favorite Food"
                outlined
              >
              </v-text-field>
            </v-col>

            <v-col>
              <v-text-field
                :rules="[(v) => !!v || 'Vacation Spot is required']"
                v-model="formData.vocation"
                type="text"
                label="Vacation Spot"
                placeholder="Enter Vacation Spot"
                outlined
              >
              </v-text-field>
            </v-col>

            <v-col>
              <v-textarea
                :rules="[(v) => !!v || 'Future plan is required']"
                v-model="formData.plans"
                outlined
                label="Plans for After High School"
                placeholder="Enter Plans"
              >
              </v-textarea>
            </v-col>
          </div>
        </div>

        <div style="text-align: center; margin-bottom: 50px">
          <v-btn
            type="submit"
            style="
              color: white;
              background-color: #4173a3;
              width: 200px;
              height: 45px;
              font-size: 18px;
            "
            >Submit
            <v-icon style="margin-left: 6px" small color="white">send</v-icon>
          </v-btn>
        </div>
      </v-form>

      <div class="add2"></div>
    </div>

    <v-snackbar
      v-model="snackbar"
      left
      color="#4173A3"
      style="margin-bottom: 60px"
    >
      {{ 'Submitted Successsfully' }}
      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="snackbar = false">
          <v-icon color="white">close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <loading v-model="loading" message="Submitting..."></loading>
  </div>
</template>

<script>
import axios from 'axios';
import loading from '../components/LoadingDialog.vue';

export default {
  name: 'SubmitForm',

  data() {
    return {
      searchTerm: null,
      schoolLoading: false,
      snackbar: false,
      loading: false,
      sport: false,
      academic: false,
      formData: {
        type: 'Student',
        image: null,
        name: null,
        category: null,
        subject: null,
        sport: null,
        school: null,
        summary: null,
        bio: null,
        honor: null,
        hobby: null,
        movie: null,
        song: null,
        tvShow: null,
        food: null,
        vocation: null,
        plans: null
      },
      categories: ['Academic', 'Sports'],
      radios: ['Parent', 'Student', 'Athletic Director', 'Academic Advisor'],
      schools: null
    };
  },
  methods: {
    searchSchools() {
      if (this.searchTerm) {
        this.schools = this.schools.filter((school) => {
          return this.searchTerm
            .toLowerCase()
            .split(' ')
            .every((v) => school.name.toLowerCase().includes(v));
        });
      } else {
        this.loadSchools();
      }
    },
    categoryType() {
      if (this.formData.category == 'Academic') {
        this.sport = false;
        this.formData.sport = 'null';
        this.formData.subject = null;
        this.academic = true;
      } else {
        this.academic = false;
        this.formData.subject = 'null';
        this.formData.sport = null;
        this.sport = true;
      }
    },

    async loadSchools() {
      this.schoolLoading = true;
      await axios
        .get('/school')
        .then((result) => {
          this.schools = result.data;
          this.schoolLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let formImage = new FormData();
        formImage.append('file', this.formData.image);
        await axios
          .post('/save-image', formImage)
          .then(async (response) => {
            await axios
              .post('/student', {
                type: this.formData.type,
                image: {
                  name: response.data.name,
                  path: response.data.path
                },
                name: this.formData.name,
                category: this.formData.category,
                subject: this.formData.subject,
                sport: this.formData.sport,
                school: this.formData.school,
                summary: this.formData.summary,
                bio: this.formData.bio,
                honor: this.formData.honor,
                favoriteHobbies: this.formData.hobby,
                favoriteMovies: this.formData.movie,
                favoriteSong: this.formData.song,
                favoriteTvShow: this.formData.tvShow,
                favoriteFood: this.formData.food,
                vacationSpot: this.formData.vocation,
                futurePlan: this.formData.plans
              })
              .then((result) => {
                this.loading = false;
                this.$refs.form.reset();
                this.snackbar = true;
                console.log(result.data);
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        console.log('form validation error');
      }
    }
  },
  mounted() {
    this.loadSchools();
  },
  components: {
    loading
  }
};
</script>

<style scoped>
.formMain {
  display: flex;
}

.form {
  width: 80%;
}

.formLineMain {
  display: flex;
}

.formLine {
  width: 50%;
  margin: 20px;
}

.add2 {
  margin: 90px 10px 0px 10px;
  /* border: 1px solid grey; */
  width: 20%;
  height: 1000px;
}

@media screen and (max-width: 1030px) {
  .formMain {
    flex-direction: column;
    align-items: center;
  }

  .form {
    width: 100%;
  }

  .add2 {
    width: 90%;
    height: 100px;
    margin: 0px 0px 30px 0px;
  }
}

@media screen and (max-width: 800px) {
  .formLineMain {
    flex-direction: column;
  }

  .formLine {
    margin: 0px;
    width: 98%;
  }
}

@media screen and (max-width: 400px) {
  .applyRow {
    flex-direction: column;
  }
}
</style>
